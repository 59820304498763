<script lang="ts" setup>
import { QTableProps } from 'quasar';
import { ref } from 'vue';

import { useSchemas } from '@/schemas/schemas.store.ts';
import { useApi } from '@/shared/model/api.store';

const api = useApi();
const schemas = useSchemas().schemas;
const schemaNumber = ref(schemas[0]['id']);
let inputModels: { [id: string]: number } = {};

for (let i = 0; i < schemas.length; i++) {
    const schema = schemas[i];
    for (let j = 0; j < schema['throttle_elements'].length; j++) {
        const element = schema['throttle_elements'][j];
        inputModels[element['id']] = 0;
    }
}

function exportSchemaIDs() {
    let IDs: number[] = [];
    for (let i = 0; i < schemas.length; i++) {
        IDs.push(schemas[i]['id']);
    }
    return IDs;
}

function chooseSchemaByID(id: number): number {
    let result = 0;
    for (let i = 0; i < schemas.length; i++) {
        if (schemas[i]['id'] === id) {
            result = i;
        }
    }
    return result;
}

const columns: QTableProps['columns'] = [
    { name: 'id', label: 'ID', field: 'id' },
    { name: 'name', label: 'Name', field: 'name' },
    { name: 'description', label: 'Description', field: 'description' },
    { name: 'type', label: 'Type', field: 'type' },
    { name: 'actions', label: 'Actions', field: '', style: 'width: 152px' },
];
</script>

<template>
    <q-select
        v-model="schemaNumber"
        :options="exportSchemaIDs()"
        label="Схема №"
        outlined
    />
    <QExpansionItem label="Pumps">
        <QTable
            :columns="columns"
            :rows="schemas[chooseSchemaByID(schemaNumber)]['pump_elements']"
        >
            <template #body-cell-actions="$props">
                <q-td :props="$props">
                    <QBtn
                        class="table__on-button"
                        label="On"
                        @click="api.sendPumpCommand($props.row.id, 'on')"
                    />
                    <QBtn
                        class="table__off-button"
                        label="Off"
                        @click="api.sendPumpCommand($props.row.id, 'off')"
                    />
                </q-td>
            </template>
        </QTable>
    </QExpansionItem>
    <QExpansionItem label="Tubes">
        <QTable
            :columns="columns"
            :rows="schemas[chooseSchemaByID(schemaNumber)]['tube_elements']"
        />
    </QExpansionItem>
    <QExpansionItem label="Valves">
        <QTable
            :columns="columns"
            :rows="schemas[chooseSchemaByID(schemaNumber)]['valve_elements']"
        >
            <template #body-cell-actions="$props">
                <q-td :props="$props">
                    <QBtn
                        class="table__on-button"
                        label="Open"
                        @click="api.sendValveCommand($props.row.id, 'open')"
                    />
                    <QBtn
                        class="table__off-button"
                        label="Close"
                        @click="api.sendValveCommand($props.row.id, 'close')"
                    />
                </q-td>
            </template>
        </QTable>
    </QExpansionItem>
    <QExpansionItem label="Throttles">
        <QTable
            :columns="columns"
            :rows="schemas[chooseSchemaByID(schemaNumber)]['throttle_elements']"
        >
            <template #body-cell-actions="$props">
                <q-td :props="$props">
                    <div class="table__throttle-input-group">
                        <QInput
                            v-model="inputModels[$props.row.id]"
                            class="table__throttle-value-input"
                            dense
                        />
                        <QBtn
                            class="table__on-button"
                            label="Set"
                            @click="
                                api.sendThrottleCommand(
                                    $props.row.id,
                                    +inputModels[$props.row.id]
                                );
                                inputModels[$props.row.id] = 0;
                            "
                        />
                    </div>
                </q-td>
            </template>
        </QTable>
    </QExpansionItem>
    <QExpansionItem label="Tanks">
        <QTable
            :columns="columns"
            :rows="schemas[chooseSchemaByID(schemaNumber)]['tank_elements']"
        />
    </QExpansionItem>
</template>

<style lang="scss">
.table {
    &__on-button {
        background-color: green;
    }

    &__off-button {
        background-color: red;
    }

    &__throttle-value-input {
        height: 20px;
    }

    &__throttle-input-group {
        display: flex;
        justify-content: space-between;
    }
}
</style>
