<script lang="ts" setup>
import { computed } from 'vue';

import { useSchemas } from '@/schemas/schemas.store.ts';
import { TankElementDto } from '@/shared/model/api.store.ts';

const props = defineProps<{
    element: TankElementDto;
}>();

const schemas = useSchemas();

const elementPositioning = computed(() => {
    return {
        left: (props.element.ui.x / schemas.schemasSize.x) * 100 + '%',
        top: (props.element.ui.y / schemas.schemasSize.y) * 100 + '%',
    };
});

const pressureStatus = computed(() => {
    return propertyStatus(
        props.element.state.pressure_red_zone_low,
        props.element.state.pressure_orange_zone_low,
        props.element.state.pressure_orange_zone_high,
        props.element.state.pressure_red_zone_high,
        props.element.state.pressure
    );
});

const temperatureStatus = computed(() => {
    return propertyStatus(
        props.element.state.temperature_red_zone_low,
        props.element.state.temperature_orange_zone_low,
        props.element.state.temperature_orange_zone_high,
        props.element.state.temperature_red_zone_high,
        props.element.state.temperature
    );
});

const massStatus = computed(() => {
    return propertyStatus(
        props.element.state.mass_red_zone_low,
        props.element.state.mass_orange_zone_low,
        props.element.state.mass_orange_zone_high,
        props.element.state.mass_red_zone_high,
        props.element.state.mass
    );
});

const volumeStatus = computed(() => {
    return propertyStatus(
        props.element.state.volume_red_zone_low,
        props.element.state.volume_orange_zone_low,
        props.element.state.volume_orange_zone_high,
        props.element.state.volume_red_zone_high,
        props.element.state.volume
    );
});

function propertyStatus(
    redLowZone: number,
    orangeLowZone: number,
    orangeHighZone: number,
    redZoneHigh: number,
    value: number
) {
    if (redLowZone <= value && value <= orangeLowZone) {
        return 'warning';
    }

    if (orangeHighZone <= value && value <= redZoneHigh) {
        return 'warning';
    }

    if (value < redLowZone || value > redZoneHigh) {
        return 'alarm';
    }

    return 'ok';
}

const level = computed(() => {
    return (props.element.state.level / 110) * 100 + '%';
});
</script>

<template>
    <div :style="elementPositioning" class="tank">
        <div class="tank__info-part">
            <div class="tank__property">
                <span class="tank__property-name">P:</span>
                <span
                    :class="{
                        'tank__property-value--warning':
                            pressureStatus === 'warning',
                        'tank__property-value--alarm':
                            pressureStatus === 'alarm',
                    }"
                    class="tank__property-value"
                >
                    {{ props.element.state.pressure }} Mpa
                </span>
            </div>
            <div class="tank__property">
                <span class="tank__property-name">T:</span>
                <span
                    :class="{
                        'tank__property-value--warning':
                            temperatureStatus === 'warning',
                        'tank__property-value--alarm':
                            temperatureStatus === 'alarm',
                    }"
                    class="tank__property-value"
                >
                    {{ props.element.state.temperature }} °C
                </span>
            </div>
            <div class="tank__property">
                <span class="tank__property-name">M:</span>
                <span
                    :class="{
                        'tank__property-value--warning':
                            massStatus === 'warning',
                        'tank__property-value--alarm': massStatus === 'alarm',
                    }"
                    class="tank__property-value"
                >
                    {{ props.element.state.mass }} t
                </span>
            </div>
            <div class="tank__property">
                <span class="tank__property-name">V:</span>
                <span
                    :class="{
                        'tank__property-value--warning':
                            volumeStatus === 'warning',
                        'tank__property-value--alarm': volumeStatus === 'alarm',
                    }"
                    class="tank__property-value"
                >
                    {{ props.element.state.volume }} %
                </span>
            </div>
            <div class="tank__property">
                <span class="tank__property-name">90%:</span>
                <div
                    :class="{
                        'tank__limit-switch--ok':
                            props.element.state.limit_switch_90,
                        'tank__limit-switch--warning':
                            props.element.state.limit_switch_100,
                        'tank__limit-switch--alarm':
                            props.element.state.limit_switch_110,
                    }"
                    class="tank__limit-switch"
                ></div>
            </div>
            <div class="tank__property">
                <span class="tank__property-name">100%:</span>
                <div
                    :class="{
                        'tank__limit-switch--warning':
                            props.element.state.limit_switch_100,
                        'tank__limit-switch--alarm':
                            props.element.state.limit_switch_110,
                    }"
                    class="tank__limit-switch"
                ></div>
            </div>
            <div class="tank__property">
                <span class="tank__property-name">110%:</span>
                <div
                    :class="{
                        'tank__limit-switch--alarm':
                            props.element.state.limit_switch_110,
                    }"
                    class="tank__limit-switch"
                ></div>
            </div>
        </div>
        <div class="tank__level-part">
            <div class="tank__level">
                <div
                    :class="{
                        'tank__level-indicator--warning':
                            props.element.state.limit_switch_100,
                        'tank__level-indicator--alarm':
                            props.element.state.limit_switch_110,
                    }"
                    class="tank__level-indicator"
                ></div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.tank {
    position: absolute;
    display: flex;
    min-width: 140px;
    min-height: 210px;
    border: 1px solid #466e96;
    border-radius: 3px;
    font-size: 12px;
    transform-origin: 50% 50%;
    white-space: nowrap;

    &__info-part {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 10px;
        background-color: #20252b;
        gap: 10px;
    }

    &__property {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
    }

    &__property-name {
        color: #424c5f;
    }

    &__property-value {
        &--warning {
            color: var(--q-warning);
        }

        &--alarm {
            color: var(--q-negative);
        }
    }

    &__level-part {
        padding: 10px;
        background-color: transparent;
    }

    &__level {
        position: relative;
        width: 13px;
        height: 100%;
        background: url('../assets/tank-level.png') no-repeat top / cover;
    }

    &__level-indicator {
        position: absolute;
        bottom: v-bind(level);
        left: 50%;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #bdbfc1;
        transform: translate(-50%, 0);
        transition: bottom 0.5s;

        &--warning {
            background-color: var(--q-warning);
        }

        &--alarm {
            background-color: var(--q-negative);
        }
    }

    &__limit-switch {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #646464;

        &--ok {
            background-color: var(--q-positive);
        }

        &--warning {
            background-color: var(--q-warning);
        }

        &--alarm {
            background-color: var(--q-negative);
        }
    }
}
</style>
