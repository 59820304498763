<script lang="ts" setup>
import { ref } from 'vue';

const props = withDefaults(
    defineProps<{
        label: string;
        desc: string;
        spaced?: boolean; // +45px
        routeName: string;
    }>(),
    { spaced: false }
);

const spacerMargin = ref('0px');

if (props.spaced) {
    spacerMargin.value = '45px';
}
</script>

<template>
    <router-link :to="{ name: props.routeName }" class="navigation-item__link">
        <q-item
            v-ripple
            :style="{ 'margin-left': spacerMargin }"
            class="navigation-item"
            clickable
        >
            <q-item-section>
                <q-item-label class="navigation-item__label">
                    {{ label }}
                </q-item-label>
                <q-item-label class="navigation-item__desc">
                    {{ desc }}
                </q-item-label>
            </q-item-section>
        </q-item>
    </router-link>
</template>

<style lang="scss" scoped>
.navigation-item {
    background-color: var(--q-dark-page);

    &__label {
        font-size: 20px;
        font-weight: bold;
    }

    &__desc {
        font-size: 12px;
    }

    &__link {
        width: 279px;
        margin-top: 15px;
        color: inherit;
        float: right;
        text-decoration: none;
    }
}
</style>
