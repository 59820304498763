import {
    Notify,
    QBtn,
    QCard,
    QCardSection,
    QDrawer,
    QExpansionItem,
    QFooter,
    QHeader,
    QIcon,
    QInput,
    QLayout,
    QPage,
    QPageContainer,
    QSlider,
    QTable,
    QToolbar,
    QToolbarTitle,
    Quasar,
} from 'quasar';
import { createApp } from 'vue';

// Import Quasar css
import { createPinia } from 'pinia';
import piniaPluginPersistedState from 'pinia-plugin-persistedstate';
import 'quasar/src/css/index.sass';

import iconSet from 'quasar/icon-set/svg-material-icons';

import App from '@/app/App.vue';
import { router } from '@/router';

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);

export const readyApp = createApp(App)
    .use(router)
    .use(pinia)
    .use(Quasar, {
        plugins: {
            Notify,
        },

        config: {
            dark: true,
        },

        iconSet: iconSet,

        components: {
            QBtn,
            QCard,
            QCardSection,
            QPage,
            QTable,
            QLayout,
            QExpansionItem,
            QPageContainer,
            QHeader,
            QToolbar,
            QToolbarTitle,
            QDrawer,
            QFooter,
            QIcon,
            QInput,
            QSlider,
        },
    });
