import { createRouter, createWebHistory } from 'vue-router';

import ErrorLogPage from '@/pages/ErrorLogPage.vue';
import EventLogPage from '@/pages/EventLogPage.vue';
import SchemaElementsPage from '@/pages/SchemaElementsPage.vue';
import SchemaPage from '@/pages/SchemaPage.vue';

const routes = [
    {
        path: '/',
        name: 'schema',
        component: SchemaPage,
    },
    {
        path: '/errorlogs',
        name: 'errorLogs',
        component: ErrorLogPage,
    },
    {
        path: '/eventlogs',
        name: 'eventLogs',
        component: EventLogPage,
    },
    {
        path: '/schemaelements',
        name: 'schemaElements',
        component: SchemaElementsPage,
    },
];
const router = createRouter({
    history: createWebHistory(),
    scrollBehavior: function (to) {
        if (to.hash) {
            return { el: to.hash, behavior: 'smooth' };
        } else {
            return { left: 0, top: 0 };
        }
    },
    routes,
});

export { router };
