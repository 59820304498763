<script lang="ts" setup>
import { computed } from 'vue';

import ShipSchema from '@/schemas/elements/ui/ShipSchema.vue';
import { useSchemas } from '@/schemas/schemas.store.ts';

const firstSchema = computed(() => {
    return useSchemas().schemas[0];
});
</script>

<template>
    <QPage class="schema-page">
        <ShipSchema :schema="firstSchema" />
    </QPage>
</template>

<style lang="scss" scoped>
.schema-page {
    display: flex;
}
</style>
