<script lang="ts" setup>
import { computed } from 'vue';

import ElementButtonControls from '@/schemas/control-panel/ui/ElementBinaryControls.vue';
import ElementRangeControls from '@/schemas/control-panel/ui/ElementRangeControls.vue';
import {
    CommandableElement,
    sendCommandToElement,
} from '@/schemas/elements/composables/send-command-to-element.ts';

const props = withDefaults(
    defineProps<{
        elements: CommandableElement[];
        rounded?: boolean;
    }>(),
    {
        rounded: false,
    }
);

const elementsType = computed(() => {
    return props.elements[0]?.type;
});

const controlsType = computed(() => {
    if (props.elements.length === 0) {
        return '';
    }

    switch (elementsType.value) {
        case 'pump':
            return 'buttons';
        case 'valve':
            return 'buttons';
        case 'throttle':
            return 'range';
        default:
            throw new Error('Selected element type was not handled correctly');
    }
});

const controlButtonsText = computed(() => {
    switch (elementsType.value) {
        case 'pump':
            return {
                positive: 'On',
                negative: 'Off',
            };
        case 'valve':
            return {
                positive: 'Open',
                negative: 'Close',
            };
        default:
            throw new Error('Selected element type was not handled correctly');
    }
});

function handlePositiveAction() {
    for (const element of props.elements) {
        switch (element.type) {
            case 'pump':
                sendCommandToElement(
                    { id: element.id, type: element.type },
                    'on'
                );
                break;
            case 'valve':
                sendCommandToElement(
                    { id: element.id, type: element.type },
                    'open'
                );
                break;
        }
    }
}

function handleNegativeAction() {
    for (const element of props.elements) {
        switch (element.type) {
            case 'pump':
                sendCommandToElement(
                    { id: element.id, type: element.type },
                    'off'
                );
                break;
            case 'valve':
                sendCommandToElement(
                    { id: element.id, type: element.type },
                    'close'
                );
                break;
        }
    }
}

function sendCommandsToThrottles(value: number) {
    for (const element of props.elements) {
        sendCommandToElement(
            { id: element.id, type: element.type },
            String(value)
        );
    }
}
</script>

<template>
    <ElementButtonControls
        v-if="controlsType === 'buttons'"
        :rounded="rounded"
        @positive-action="handlePositiveAction"
        @negative-action="handleNegativeAction"
    >
        <template #positive-button-content>
            {{ controlButtonsText.positive }}
        </template>
        <template #negative-button-content>
            {{ controlButtonsText.negative }}
        </template>
    </ElementButtonControls>
    <ElementRangeControls
        v-if="controlsType === 'range'"
        :default-value="props.elements[0].state.value as number"
        @new-value="(v) => sendCommandsToThrottles(v)"
    />
</template>
