<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';

import { useElementBounding } from '@vueuse/core';

import { useSchemas } from '@/schemas/schemas.store.ts';

const props = withDefaults(
    defineProps<{
        // Horizontal axis coordinat on abstract scheme (0-1920)
        x: number;
        // Vertical axis coordinat on abstract scheme (0-1024)
        y: number;
        tag?: string;
    }>(),
    {
        tag: 'div',
    }
);

const schemas = useSchemas();

const elementPositioning = computed(() => {
    return {
        left: (props.x / schemas.schemasSize.x) * 100 + '%',
        top: (props.y / schemas.schemasSize.y) * 100 + '%',
    };
});

const cardElement = ref<HTMLElement | null>(null);
const roundElement = ref<HTMLElement | null>(null);

const cardRect = useElementBounding(cardElement, {});
const roundRect = useElementBounding(roundElement);

let correctTransformation = ref<{ transform: string }>({ transform: '' });

onMounted(() => {
    const xCorrection =
        cardRect.left.value -
        (roundRect.left.value + roundRect.width.value / 2);
    const yCorrection =
        cardRect.top.value - (roundRect.top.value + roundRect.height.value / 2);

    // Log all data that we need to calculate correction with annotations
    correctTransformation.value = {
        transform: `translate(${xCorrection}px, ${yCorrection}px)`,
    };
});
</script>

<template>
    <component
        :is="tag"
        ref="cardElement"
        :style="[correctTransformation, elementPositioning]"
        class="card-element"
    >
        <div ref="elementTop" class="card-element__top">
            <slot name="top"></slot>
        </div>
        <div ref="roundElement" class="card-element__round">
            <slot name="round"></slot>
        </div>
        <div class="card-element__bottom">
            <slot name="bottom"></slot>
        </div>
    </component>
</template>

<style lang="scss" scoped>
.card-element {
    position: absolute;
    z-index: 5;
    width: 64px;
    padding-top: 3px;
    border-radius: 4px;
    background-color: var(--q-dark-page);
    color: white;
    transition: 0.2s background-color;

    &:hover {
        background-color: var(--q-dark);
    }

    &__top {
        overflow: hidden;
        color: #bdbfc1;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        white-space: nowrap;
    }

    &__round {
        position: relative;
        width: 100%;
        margin: 3px 0;
        aspect-ratio: 1 / 1;
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        margin-bottom: 3px;
    }
}
</style>
