import { ref } from 'vue';

import { defineStore } from 'pinia';

import { useApi } from '@/shared/model/api.store.ts';

export const useErrorLogs = defineStore('errorLogs', () => {
    const errorLogs = ref();

    async function loadErrorLogs() {
        errorLogs.value = await useApi().getErrorLogs();
    }

    return { errorLogs, loadErrorLogs };
});
