<script lang="ts" setup>
import { ref } from 'vue';

const props = defineProps<{
    defaultValue: number;
}>();

const emit = defineEmits<{
    (e: 'new-value', value: number): void;
}>();

const innerValue = ref(props.defaultValue);
</script>

<template>
    <QSlider
        v-model="innerValue"
        :max="90"
        :min="0"
        :step="10"
        class="range-control"
        color="positive"
        dense
        label
        markers
        size="68px"
        thumb-size="14px"
        track-color="grey-9"
        track-size="3px"
        @change="emit('new-value', innerValue)"
    />
</template>

<style lang="scss" scoped>
.range-control {
    min-width: 150px;
}
</style>
