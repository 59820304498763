export type NavigationConfig = Array<{
    name: string;
    label: string;
    desc: string;
    spaced?: boolean;
}>;

export const navigations: NavigationConfig = [
    {
        name: 'schema',
        label: 'Schema',
        desc: 'Ship schema',
    },
    {
        name: 'errorLogs',
        label: 'Error Logs',
        desc: 'All error reports are listed here',
    },
    {
        name: 'eventLogs',
        label: 'Event Logs',
        desc: 'All event reports are listed here',
    },
    {
        name: 'schemaElements',
        label: 'Schema Elements Table',
        desc: 'All schema elements are listed here',
    },
];
