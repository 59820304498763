import { computed, ComputedRef, Ref, ref } from 'vue';

import {
    CommandableElement,
    sendCommandToElement,
} from '@/schemas/elements/composables/send-command-to-element.ts';

export function useElementsControlPanel(): ElementsControlPanelI {
    const selectedElements = ref<CommandableElement[]>([]);

    const currentElementsType = computed(() => {
        return selectedElements.value[0]?.type;
    });

    function selectElement(element: CommandableElement) {
        for (const currentElement of selectedElements.value) {
            if (element.id === currentElement.id) {
                selectedElements.value = selectedElements.value.filter(
                    (el) => el.id !== element.id
                );
                return;
            }
        }

        if (
            element.type !== currentElementsType.value &&
            selectedElements.value.length > 0
        ) {
            throw new Error('Нельзя выбрать элементы разного типа');
        }

        selectedElements.value.push(element);
    }

    function sendCommandsToSelected(command: string) {
        const commandPromises = [];

        for (const element of selectedElements.value) {
            commandPromises.push(
                sendCommandToElement(
                    { id: element.id, type: element.type },
                    command
                )
            );
        }

        return Promise.all(commandPromises);
    }

    return {
        selectedElements,
        currentElementsType,
        selectElement,
        sendCommandsToSelected,
    };
}

export interface ElementsControlPanelI {
    selectedElements: Ref<CommandableElement[]>;
    selectElement: (element: CommandableElement) => void;
    sendCommandsToSelected: (command: string) => Promise<any>;
    currentElementsType: ComputedRef<string>;
}
