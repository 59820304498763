import axios from 'axios';
import { defineStore } from 'pinia';

export const useApi = defineStore('api', () => {
    const api = axios.create({
        baseURL: import.meta.env.VITE_API_URL,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    async function getSchemas(): Promise<Array<SchemaDto>> {
        const result = await api.get<Array<SchemaDto>>('/schemas');
        return result.data;
    }

    async function getEventLogs() {
        const result = await api.get<Array<EventLogDto>>('/event-logs');
        return result.data;
    }

    async function getErrorLogs() {
        const result = await api.get<Array<ErrorLogDto>>('/error-logs');
        return result.data;
    }

    async function sendPumpCommand(pumpID: string, command: string) {
        const result = await api.post('/commands/pump', {
            pump_id: pumpID,
            command,
        });
        return result.data;
    }

    async function sendValveCommand(valveID: string, command: string) {
        const result = await api.post('/commands/valve', {
            valve_id: valveID,
            command,
        });
        return result.data;
    }

    async function sendThrottleCommand(throttleID: string, command: number) {
        const result = await api.post('/commands/throttle', {
            throttle_id: throttleID,
            command,
        });
        return result.data;
    }

    return {
        getSchemas,
        getEventLogs,
        getErrorLogs,
        sendPumpCommand,
        sendValveCommand,
        sendThrottleCommand,
    };
});

export type ErrorLogDto = {
    created_at: string;
    text: string;
};

export type EventLogDto = {
    created_at: string;
    text: string;
};

export type SchemaDto = {
    id: number;
    tube_elements: Array<TubeElementDto>;
    valve_elements: Array<ValveElementDto>;
    pump_elements: Array<PumpElementDto>;
    tank_elements: Array<TankElementDto>;
    throttle_elements: Array<ThrottleElementDto>;
};

export type SchemaElementDto = {
    id: string;
    name: string;
    description: string;
    type: string;
};

export type TubeElementDto = {
    type: 'tube';
    state: {
        full: boolean;
    };
    ui: {
        x: number;
        y: number;
        width: number;
        rotation: number;
    };
} & SchemaElementDto;

export type ValveElementDto = {
    type: 'valve';
    state: {
        actual: boolean;
        value: 'opened' | 'closed';
        last_command: 'open' | 'close' | '';
        last_command_time: null;
        error: boolean;
    };
    ui: RoundUiDto;
} & SchemaElementDto;

export type RoundUiDto = {
    x: number;
    y: number;
    rotation: number;
};

export type PumpElementDto = {
    type: 'pump';
    state: {
        actual: boolean;
        value: 'on' | 'off';
        last_command: 'on' | 'off' | '';
        last_command_time: null;
        error: boolean;
    };
    ui: RoundUiDto;
} & SchemaElementDto;

export type TankElementDto = {
    type: 'tank';
    state: TankElementStateDto &
        PressureStateDto &
        TemperatureStateDto &
        MassStateDto &
        VolumeStateDto;
    ui: {
        x: number;
        y: number;
    };
} & SchemaElementDto;

export type ThrottleElementDto = {
    type: 'throttle';
    ui: RoundUiDto;
    state: ThrottleElementStateDto;
} & SchemaElementDto;

export type ThrottleElementStateDto = {
    id: number;
    actual?: boolean;
    value: number;
    error: boolean;
    last_command: number;
    last_command_time: string;
    throttle_id: string;
};

export type TankElementStateDto = {
    id: number;
    tank_id: string;
    salinity: number;
    tank_volume: number;
    level: number;
    limit_switch_90?: boolean;
    limit_switch_100?: boolean;
    limit_switch_110?: boolean;
};

export type PressureStateDto = {
    pressure: number;
    pressure_red_zone_low: number;
    pressure_orange_zone_low: number;
    pressure_orange_zone_high: number;
    pressure_red_zone_high: number;
};

export type TemperatureStateDto = {
    temperature: number;
    temperature_red_zone_low: number;
    temperature_orange_zone_low: number;
    temperature_orange_zone_high: number;
    temperature_red_zone_high: number;
};

export type MassStateDto = {
    mass: number;
    mass_red_zone_low: number;
    mass_orange_zone_low: number;
    mass_orange_zone_high: number;
    mass_red_zone_high: number;
};

export type VolumeStateDto = {
    volume: number;
    volume_red_zone_low: number;
    volume_orange_zone_low: number;
    volume_orange_zone_high: number;
    volume_red_zone_high: number;
};
