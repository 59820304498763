<script setup lang="ts">
import { ref } from 'vue';

import { QTableProps } from 'quasar';

import { useEventLogs } from '@/tables/event/model/event_logs.store.ts';

const eventLogs = useEventLogs().eventLogs;

const selected = ref([]);

const columns: QTableProps['columns'] = [
    {
        name: 'created_at',
        label: 'Время',
        field: 'id', // created_at
        sortable: true,
        style: 'width: 100px',
        align: 'right',
    },
    { name: 'description', label: 'Описание', field: 'text', align: 'left' },
];
</script>

<template>
    <div>
        <QTable
            v-model:selected="selected"
            :columns="columns"
            :rows="eventLogs"
            flat
            bordered
            row-key="id"
            title="Reports"
            selection="multiple"
            table-class="event-table"
        />
    </div>
</template>

<style lang="scss">
.event-table {
    height: 600px;
}
</style>
