<script lang="ts" setup>
import { ref, watch } from 'vue';

const emit = defineEmits(['positive-action', 'negative-action']);

const sliderValue = ref<number>(0);

watch(sliderValue, (v) => {
    if (v) {
        emit('positive-action');
    } else {
        emit('negative-action');
    }
});
</script>

<template>
    <QSlider
        v-model="sliderValue"
        :max="1"
        :min="0"
        :step="1"
        class="binary-controls"
        color="positive"
        dense
        label
        markers
        thumb-size="14px"
        track-size="3px"
    />
</template>

<style lang="scss" scoped>
.binary-controls {
    min-width: 150px;
}
</style>
