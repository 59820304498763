import {
    PumpElementDto,
    ThrottleElementDto,
    useApi,
    ValveElementDto,
} from '@/shared/model/api.store.ts';

export async function sendCommandToElement(
    element: { id: string; type: CommandableElement['type'] },
    command: string
) {
    const api = useApi();

    switch (element.type) {
        case 'valve':
            await api.sendValveCommand(element.id, command);
            break;

        case 'pump':
            await api.sendPumpCommand(element.id, command);
            break;

        case 'throttle': {
            const throttleCommand = Number(command);
            if (isNaN(throttleCommand)) {
                throw new Error(
                    'Throttle command must be convertable to number'
                );
            }
            await api.sendThrottleCommand(element.id, throttleCommand);
            break;
        }

        default:
            throw new Error(`Non commandable type was passed: ${element.type}`);
    }
}

export type CommandableElement =
    | ValveElementDto
    | PumpElementDto
    | ThrottleElementDto;
