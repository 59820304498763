<script lang="ts" setup>
import { computed } from 'vue';

import ValveSvg from '@/schemas/elements/assets/valve.svg';
import { ValveElementDto } from '@/shared/model/api.store.ts';

const props = defineProps<{
    element: ValveElementDto;
}>();

const computedRotation = computed(() => {
    let baseRotation = props.element.ui.rotation;

    if (props.element.state.value === 'closed') {
        baseRotation -= 90;
    }

    return baseRotation;
});
</script>

<template>
    <div
        :class="{
            'valve-status-round--opened': element.state.value === 'opened',
            'valve-status-round--closed': element.state.value === 'closed',
            'valve-status-round--not-actual': !element.state.actual,
        }"
        class="valve-status-round"
    >
        <div
            :class="{
                'valve-error-round--active': element.state.error,
            }"
            class="valve-error-round"
        >
            <ValveSvg
                :style="{
                    transform: `translate(-50%, -50%) rotate(${computedRotation}deg)`,
                }"
                class="valve-icon"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.valve-status-round {
    width: 100%;
    height: 100%;
    padding: 2px;
    border-radius: 50%;

    &--closed {
        border: 2px solid white;
        --icon-fill: white;
    }

    &--opened {
        border: 2px solid var(--q-positive);
        --icon-fill: var(--q-positive);
    }

    &--not-actual {
        border: 2px solid #494949;
        --icon-fill: #494949;
    }
}

.valve-error-round {
    width: 100%;
    height: 100%;
    border-radius: 50%;

    &--active {
        border: 2px solid var(--q-warning);
    }
}

.valve-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: auto;
    transform-origin: 50% 50%;
}
</style>
