<script lang="ts" setup>
import { useQuasar } from 'quasar';

import TankElement from '@/schemas/elements/ui/TankElement.vue';

import ValveElement from '@/schemas/elements/ui/ValveElement.vue';
import TubeElement from '@/schemas/elements/ui/TubeElement.vue';
import { SchemaDto } from '@/shared/model/api.store.ts';
import PumpElement from '@/schemas/elements/ui/PumpElement.vue';
import ThrottleElement from '@/schemas/elements/ui/ThrottleElement.vue';
import { useElementsControlPanel } from '@/schemas/control-panel/model/elements-control-panel.ts';
import ElementsControlPanel from '@/schemas/control-panel/ui/ElementsControlPanel.vue';
import { useSchemas } from '@/schemas/schemas.store.ts';
import { CommandableElement } from '@/schemas/elements/composables/send-command-to-element.ts';

const quasar = useQuasar();

defineProps<{
    schema: SchemaDto;
}>();

const controlPanelModel = useElementsControlPanel();

const schemasSizeX = useSchemas().schemasSize.x;
const schemasSizeY = useSchemas().schemasSize.y;

function selectElement(el: CommandableElement) {
    try {
        controlPanelModel.selectElement(el);
    } catch (e) {
        if (e instanceof Error) {
            quasar.notify({
                type: 'negative',
                message: e.message,
                position: 'top',
            });
        } else {
            throw new Error('Non expected error was thrown');
        }
    }
}
</script>

<template>
    <section class="schema-block">
        <div class="schema-safety-zone">
            <div class="schema">
                <ValveElement
                    v-for="element in schema.valve_elements"
                    :key="element.id"
                    :class="{
                        'schema__commandable-element--selected':
                            controlPanelModel.selectedElements.value.find(
                                (el) => {
                                    return el.id === element.id;
                                }
                            ),
                    }"
                    :element="element"
                    class="schema__commandable-element"
                    tag="button"
                    @click="selectElement(element)"
                />
                <PumpElement
                    v-for="element in schema.pump_elements"
                    :key="element.id"
                    :class="{
                        'schema__commandable-element--selected':
                            controlPanelModel.selectedElements.value.find(
                                (el) => {
                                    return el.id === element.id;
                                }
                            ),
                    }"
                    :element="element"
                    class="schema__commandable-element"
                    tag="button"
                    @click="selectElement(element)"
                />

                <ThrottleElement
                    v-for="element in schema.throttle_elements"
                    :key="element.id"
                    :class="{
                        'schema__commandable-element--selected':
                            controlPanelModel.selectedElements.value.find(
                                (el) => {
                                    return el.id === element.id;
                                }
                            ),
                    }"
                    :element="element"
                    class="schema__commandable-element"
                    tag="button"
                    @click="selectElement(element)"
                />
                <TubeElement
                    v-for="element in schema.tube_elements"
                    :key="element.id"
                    :element="element"
                />
                <TankElement
                    v-for="element in schema.tank_elements"
                    :key="element.id"
                    :element="element"
                />
            </div>
        </div>
        <ElementsControlPanel :control-panel-model="controlPanelModel" />
    </section>
</template>

<style lang="scss" scoped>
.schema-block {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.schema-safety-zone {
    width: 75%;
    max-height: 74vh;
    flex-grow: 1;
    padding: 75px 75px;
}

.schema {
    position: relative;
    top: 50%;
    border: 1px dashed rgba($color: #ffffff, $alpha: 0.1);
    aspect-ratio: v-bind(schemasSizeX) / v-bind(schemasSizeY);
    transform: translateY(-50%);

    &__commandable-element {
        border: 0;
        cursor: pointer;

        &:focus-visible {
            outline: 1px solid var(--q-info);
        }

        &--selected {
            outline: 1px solid var(--q-warning);
        }
    }
}
</style>
