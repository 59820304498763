<script lang="ts" setup>
import { matMenu } from '@quasar/extras/material-icons';
import { computed } from 'vue';

const props = defineProps({
    leftDrawerOpen: Boolean,
});

const emit = defineEmits(['update:leftDrawerOpen']);
const leftDrawerOpen = computed({
    get: () => props.leftDrawerOpen,
    set: (value) => emit('update:leftDrawerOpen', value),
});

function toggleLeftDrawer() {
    leftDrawerOpen.value = !leftDrawerOpen.value;
}
</script>

<template>
    <QHeader bordered class="bg-primary header">
        <QToolbar class="header__toolbar">
            <QToolbarTitle class="header__title"> DigitalShip</QToolbarTitle>
            <QBtn
                :icon="matMenu"
                class="header__content"
                dense
                flat
                round
                @click="toggleLeftDrawer"
            />
        </QToolbar>
    </QHeader>
</template>

<style lang="scss" scoped>
.header {
    &__toolbar {
        display: flex;
    }

    &__title {
        flex: unset;
        margin-right: 20px;
    }
}
</style>
