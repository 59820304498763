<script lang="ts" setup>
import { ref } from 'vue';

import { navigations } from '@/navigation/config/config';
import NavigationElement from '@/navigation/ui/NavigationElement.vue';
import { useSchemas } from '@/schemas/schemas.store.ts';
import { useErrorLogs } from '@/tables/error/model/error_logs.store.ts';

import TheHeader from './TheHeader.vue';

import { useEventLogs } from '@/tables/event/model/event_logs.store.ts';

const leftDrawerOpen = ref(false);

const ready = ref<boolean>(false);

useErrorLogs().loadErrorLogs();
useEventLogs().loadEventLogs();
useSchemas()
    .init()
    .then(() => {
        ready.value = true;
    });
</script>

<template>
    <div class="wrapper">
        <QLayout v-if="ready" class="layout" view="hHr lpR fFf">
            <TheHeader
                v-model:left-drawer-open="leftDrawerOpen"
                :style="{ position: 'absolute' }"
            />
            <QDrawer
                v-model="leftDrawerOpen"
                :style="{
                    position: 'absolute',
                }"
                bordered
                class="navigation-drawer"
                overlay
                side="left"
            >
                <div class="navigation-drawer__content">
                    <q-list
                        v-for="element in navigations"
                        :key="element.name"
                        class="navigation-drawer__list"
                    >
                        <NavigationElement
                            :desc="element.desc"
                            :label="element.label"
                            :route-name="element.name"
                            :spaced="element.spaced"
                        />
                    </q-list>
                </div>
            </QDrawer>
            <QPageContainer>
                <router-view />
            </QPageContainer>
        </QLayout>
        <div v-else class="loader">Подготавливаем сервис к работе</div>
    </div>
</template>

<style lang="scss">
@import 'normalize.css';
@import 'scss/global';

body {
    padding: 48px 90px 53px;
}

.q-drawer {
    top: 99px !important;
    left: 90px;
}

.q-notifications__list--top {
    top: 43px !important;
}

.q-page,
.q-layout {
    min-height: unset !important;
}
</style>

<style lang="scss" scoped>
.wrapper {
    position: relative;
}

.navigation-drawer {
    &__content {
        padding-top: 25px;
    }
}

.loader {
    display: flex;
    height: 100%;
    min-height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 30px;
}
</style>
