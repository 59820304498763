import { defineStore } from 'pinia';
import { ref } from 'vue';

import { useApi } from '@/shared/model/api.store.ts';

export const useSchemas = defineStore('schemas', () => {
    const schemasSize = {
        x: 1920,
        y: 1080,
    };
    const updateRate = 1000;
    const schemas = ref();

    async function init() {
        await loadSchemas();
        setInterval(() => {
            loadSchemas();
        }, updateRate);
    }

    async function loadSchemas() {
        schemas.value = await useApi().getSchemas();
    }

    return { schemas, schemasSize, init };
});
