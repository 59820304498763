<script lang="ts" setup>
import { computed, ref } from 'vue';

import { onKeyStroke } from '@vueuse/core';

import { useSchemas } from '@/schemas/schemas.store.ts';
import { TubeElementDto } from '@/shared/model/api.store.ts';

const props = defineProps<{
    element: TubeElementDto;
}>();

const schemasSize = useSchemas().schemasSize;

const showingTubeId = ref(false);

const tubeId = computed(() => {
    return props.element.id;
});

onKeyStroke('ArrowDown', (e) => {
    e.preventDefault();
    showingTubeId.value = !showingTubeId.value;
});
</script>

<template>
    <div
        :style="{
            width: element.ui.width + 'px',
            background: element.state.full ? '#0084FF' : '#494949',
            transform: `rotate(${element.ui.rotation}deg)`,
            left: (element.ui.x / schemasSize.x) * 100 + '%',
            top: (element.ui.y / schemasSize.y) * 100 + '%',
        }"
        class="tube"
    >
        <span v-if="showingTubeId" class="tube__id">{{ tubeId }}</span>
    </div>
</template>

<style lang="scss" scoped>
.tube {
    position: absolute;
    height: 2px;
    transform-origin: left center;

    &__id {
        position: absolute;
        z-index: 5;
        top: 50%;
        left: 50%;
        color: white;
        font-size: 9px;
        transform: translate(-50%, -50%);
    }
}
</style>
